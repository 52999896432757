import './bootstrap';
import 'chart.js';
import Alpine from 'alpinejs';
import Chart from 'chart.js/auto';
window.Chart = Chart;

// Vite
const images = import.meta.glob([
    '../pdf/polisen/**',
    '../front/images/**',
    '../images/**'
], {import: 'images'});